<template>
  <div class="viewTestPaper">
    <div class="viewTestPaper-content">
      <div class="viewTestPaper-content-title">
        <div><i class="el-icon-arrow-left title-icon" @click="backPage" /></div>
        <div class="title-text">{{ detail.name }}</div>
        <div class="title-right">
          <div v-if="publishFlag === 0" class="title-time">考试时间：{{ detail.startTime }} - {{ detail.endTime }}</div>
          <div v-else class="title-right-content">
            <div class="icon-box">
              <img src="@/assets/images/shuyi_zhengque-wancheng.png" alt="">
              正确的：{{ calculateTheQuantity(1) }}
            </div>
            <div class="icon-box" style="color:#F45454;">
              <img src="@/assets/images/cuowu.png" alt="">
              错误的：{{ calculateTheQuantity(0) }}
            </div>
            <div class="part" />
            <el-button size="mini" type="primary" @click="comment">评语</el-button>
            <el-button size="mini" type="primary" @click="openRanking">排行榜</el-button>
          </div>
        </div>
      </div>
      <div v-if="testStatus!== '已结束' && status === 0" class="viewTestPaper-content-countdown">
        倒计时:{{ examEndTime | timeFormat }}
      </div>
      <div class="viewTestPaper-content-word">
        <div v-for="(i,index) in word" :key="i.id" class="viewTestPaper-content-item">
          <Word
            :key="index"
            :title="questionType === 0?i.word:i.interpretation"
            :index="index"

            :mode="testStatus==='已结束'?'info':status === 0?'exam':'info'"

            :is-reply-show="true"
            :reply="i.answer"

            :is-answer-show="publishFlag === 1"
            :answer="questionType === 0?i.interpretation:i.word"

            :correct-flag="i.correctFlag"
          >
            <template #input>
              <el-input
                v-model="i.answer"
                :maxlength="50"
                resize="none"
                type="textarea"
                :rows="2"
                placeholder="请输入答案"
              />
            </template>
          </Word>
        </div>
      </div>
      <div v-if="testStatus!== '已结束' && status === 0" class="viewTestPaper-content-footer">
        <el-button type="primary" @click="handleSubmit">提 交</el-button>
      </div>
    </div>
    <indexBottom />
    <el-dialog
      :visible.sync="dialogVisible"
      custom-class="countdownReminder"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      center
    >
      <div class="title">
        <img width="20" src="@/assets/images/daojishi.png" alt="">
        <span class="text">考试时间已到！</span>
      </div>
      <div class="hint">系统已自动提交考试内容！提交后将无法修改</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" round @click="closePopup">知道了</el-button>
      </span>
    </el-dialog>
    <Leaderboard v-if="openLeaderboard" :type="leaderboardType" :context="detail" :open.sync="openLeaderboard" />
  </div>
</template>

<script>
import indexBottom from '@/components/indexBottom'
import Word from '@/views/courseAssignments/components/Word'
import { getTestDetail, submitTestPaper } from '@/api/course/word'
import { mapState } from 'vuex'
import Leaderboard from '@/views/courseAssignments/components/Leaderboard'

export default {
  name: 'ViewTestPaper',
  filters: {
    timeFormat(val) {
      console.log(val)
      const hours = String(parseInt((val % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0')
      const minutes = String(parseInt((val % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0')
      const seconds = String(Math.floor((val % (1000 * 60)) / 1000)).padStart(2, '0')
      return `${hours}:${minutes}:${seconds}`
    }
  },
  components: { indexBottom, Word, Leaderboard },
  data() {
    return {
      word: [],
      detail: {},
      time: null,
      examEndTime: null,
      questionType: 0, // 试题类型  0 英 =》 汉 word 1 汉 =》 英 interpretation
      dialogVisible: false,
      testStatus: '', // 返回考试状态 已结束 进行中
      status: null, // 试卷状态 0未提交 1已提交
      publishFlag: 0, // 考试公布 0未公布 1已公布
      openLeaderboard: false,
      leaderboardType: '排行榜'
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  created() {
    this.wordDetails()
  },
  destroyed() {
    clearInterval(this.time)
  },
  methods: {
    calculateTheQuantity(type) {
      return this.word.filter(i => i.correctFlag === type)?.length ?? 0
    },
    generateParams() {
      return {
        type: this.questionType,
        id: this.$route.query.id,
        stuId: this.userInfo.id,
        answerList: this.word.map(i => {
          let answer = i.answer?.replace(/\s*/g, '')
          answer = answer.replaceAll('，', ',')
          return { wordId: i.id, answer }
        })
      }
    },
    handleSubmit() {
      const params = this.generateParams()
      console.log('params', params)
      this.$confirm('确定提交考试吗？提交后答案将无法修改！', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        roundButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        showClose: false,
        customClass: 'submitPrompt'
      }).then(() => {
        console.log('手动交卷')
        this.submitData(true, params)
      }).catch(() => {
      })
    },
    submitData(hasClose, params) {
      if (hasClose) {
        this.dialogVisible = false
      }
      if (!params) {
        params = this.generateParams()
      }
      submitTestPaper(params).then(res => {
        if (res.code === 200) {
          if (hasClose) {
            this.$message.success(res.msg)
            this.backPage()
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    closePopup() {
      this.dialogVisible = false
      this.backPage()
    },
    hilarity() {
      console.log('自动交卷')
      this.dialogVisible = true
      this.submitData(false, null)
    },
    wordDetails() {
      getTestDetail(this.$route.query.id).then(res => {
        this.detail = res.data
        this.status = res.data.status
        this.testStatus = res.data.testStatus
        this.publishFlag = res.data.publishFlag
        this.questionType = res.data.type
        this.word = res.data.wordList.map(i => ({ ...i, answer: i.answer ?? '' }))
        this.examEndTime = this.$moment(res.data.endTime).valueOf() - this.$moment(new Date()).valueOf()
        if (this.testStatus !== '已结束' && this.status === 0) {
          this.setTimer()
        }
      })
    },
    setTimer() {
      clearInterval(this.time)
      this.time = setInterval(() => {
        const endTime = this.$moment(this.examEndTime).valueOf()
        if (endTime > 1000) {
          this.examEndTime -= 1000
        } else {
          this.hilarity()
          clearInterval(this.time)
        }
      }, 1000)
    },
    backPage() {
      this.$router.push({ path: '/wordDictation' })
    },
    comment() {
      this.leaderboardType = '评语'
      this.openLeaderboard = true
      // const text = this.detail.remark || ''
      // this.$confirm(text, '评语', {
      //   confirmButtonText: '关闭',
      //   center: true,
      //   roundButton: true,
      //   closeOnPressEscape: false,
      //   closeOnClickModal: false,
      //   showCancelButton: false,
      //   customClass: 'commentConfirm'
      // })
    },
    openRanking() {
      this.leaderboardType = '排行榜'
      this.openLeaderboard = true
    }
  }
}
</script>

<style lang="less">
.submitPrompt {
  padding-bottom: 20px;
  width: 340px;

  .el-message-box__header {
    padding-top: 0;
  }

  .el-button {
    padding: 9px 20px;
  }
}

.commentConfirm {
  .el-message-box__header {
    padding-top: 15px;
  }
}
</style>

<style scoped lang="less">
.viewTestPaper {
  text-align: left;

  &-content {
    width: 1200px;
    background: #FFFFFF;
    margin: 0 auto 50px;
    border-radius: 10px;
    min-height: 500px;

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 20px;

      > div {
        width: 400px;
      }

      .title-icon {
        border: 1px solid #4C97FD;
        border-radius: 50%;
        color: #4C97FD;
        cursor: pointer;
      }

      .title-text {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        text-align: center;
      }

      .title-right {
        .title-time {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          text-align: right;
        }

        &-content {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .icon-box {
            padding-right: 14px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1CBA77;

            img {
              margin-right: 4px;
            }
          }

          .part {
            width: 1px;
            height: 16px;
            background: #E7EDF6;
            margin-right: 14px;
          }
        }
      }

    }

    &-countdown {
      width: 100%;
      height: 40px;
      background: #FCF6F4;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #E7321B;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }

    &-word {
      padding: 30px;
      width: 1200px;
      min-height: 400px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
    }

    &-item {
      width: calc(100% / 4 - 15px);
      margin: 0 0 15px 15px;

      ::v-deep .el-textarea__inner:hover {
        border-color: #A5D2FF;
      }
    }

    &-footer {
      text-align: center;
      padding: 20px 0;
    }
  }

  ::v-deep .countdownReminder {
    width: 380px;
    height: 244px;
    max-height: 244px;
    min-height: 244px;
    background: #FFFFFF;
    border-radius: 10px;

    .title {
      width: 380px;
      height: 60px;
      background: #FFF7EB;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FDA04C;

      .text {
        margin-left: 7px;
      }
    }

    .hint {
      margin: 30px 0;
      text-align: center;
    }

    .el-dialog__body {
      padding: 0;
    }
  }
}
</style>
