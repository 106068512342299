<template>
  <div class="leaderboard">
    <el-dialog
      :title="type"
      :visible.sync="dialogVisible"
      center
      :before-close="beforeClose"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      destroy-on-close
      width="582px"
    >
      <div v-if="leaderboard && type === '排行榜'" class="content">
        <div v-for="(i,index) in leaderboard.stuScoreList" :key="i.id" class="content-item">
          <div class="item-left">
            <img v-if="index < 3" class="item-left-theMedal" :src="index === 0 ? one: index === 1 ? two : thr" alt="">
            <div v-else class="item-left-ordinary">
              <div class="stu-index-icon-one">
                <div class="stu-index-icon-two">
                  <div class="stu-index-icon-thr">{{ index }}</div>
                </div>
              </div>
            </div>
            <img :src="i.stuAvatar === null ? $pic : $downloadUrl + i.stuAvatar" class="item-left-avatar">
            <div class="item-left-name">{{ i.stuName }}</div>
          </div>
          <div class="item-right">
            <span style="color: #157DFB">{{ i.rightNum }}</span> /
            <span>{{ leaderboard.wordNum }}</span>
          </div>
        </div>
      </div>
      <div v-if="type === '评语'" class="comment">
        <div style="padding: 10px 0">评级：{{ comment[context.comment] }}</div>
        <div class="">评语：{{ context.remark }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" round @click="beforeClose">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { getLeaderboard } from '@/api/course/word'
import { mapState } from 'vuex'

export default {
  name: 'Leaderboard',
  props: {
    open: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    context: {
      type: Object
    }
  },
  data() {
    return {
      dialogVisible: true,
      leaderboard: null,
      one: require('@/assets/images/one.png'),
      thr: require('@/assets/images/thr.png'),
      two: require('@/assets/images/two.png')
    }
  },
  created() {
    this.getList()
  },
  computed: {
    ...mapState({
      comment: state => state.dict.dictTree['word_test_evaluation']['obj']
    })
  },
  methods: {
    getList() {
      getLeaderboard(this.$route.query.id).then(res => {
        console.log({ res })
        this.leaderboard = res.data
      })
    },
    beforeClose() {
      this.$emit('update:open', false)
    }
  }
}
</script>

<style scoped lang="less">
/*里面的代码可以根据自己需求去进行更改*/
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 8px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  color: #ffffff;
  background-color: #fff;
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #E6E6E6;
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

.leaderboard {
  ::v-deep .el-dialog {
    min-height: auto;

    .el-dialog__header {
      border-bottom: 1px solid #E7ECF2;
    }

    .el-dialog__body {
      padding: 0 20px;
    }

    .el-dialog__title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #409EFF;
    }
  }

  .content {
    height: 454px;
    background: #FFFFFF;
    overflow: auto;
    padding: 0 20px;

    .content-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #F0F4FA;
      padding: 15px 0;

      .item-left {
        display: flex;
        align-items: center;

        &-ordinary {
          width: 22px;
          height: 23px;
          position: relative;

          .stu-index-icon-one {
            position: absolute;
            top: -3px;
            width: 18px;
            height: 27px;
            overflow: hidden;
            margin: 0 auto;
            transform: rotate(120deg);
            -ms-transform: rotate(120deg);
            -moz-transform: rotate(120deg);
            -webkit-transform: rotate(120deg);

            .stu-index-icon-two {
              width: 18px;
              height: 27px;
              overflow: hidden;
              transform: rotate(-60deg);
              -ms-transform: rotate(-60deg);
              -moz-transform: rotate(-60deg);
              -webkit-transform: rotate(-60deg);

              .stu-index-icon-thr {
                transform: rotate(-60deg);
                -ms-transform: rotate(-60deg);
                -moz-transform: rotate(-60deg);
                -webkit-transform: rotate(-60deg);
                background-color: #BBBFC4;
                display: block;
                width: 18px;
                height: 27px;
                line-height: 27px;
                text-align: center;
                font-size: 12px;
                color: white;
              }
            }
          }
        }

        &-theMedal {
          width: 22px;
          height: 23px;
        }

        &-avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin: 0 14px;
        }

        &-name {
          width: 300px;
        }
      }

      .item-right {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
  }

  .comment {
    text-align: left;
  }
}
</style>
